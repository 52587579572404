import { useMemo } from 'react'
import i18n from 'i18next'
import * as Yup from 'yup'

import { prepareCustomMessages } from '../../yup'

prepareCustomMessages()

export const useSchema = () => {
	return useMemo(
		() =>
			Yup.object().shape({
				name: Yup.string().required().max(255),
				phoneNumber: Yup.string()
					.required()
					.test('empty-or-matches', i18n.t('general.validation.required'), function (value) {
						return (
							value === undefined ||
							value === null ||
							value === '' ||
							/^(\+\d{1,4}\s?)?(\d{3}\s?\d{3}\s?\d{3})$/.test(value)
						)
					})
					.max(255),
				email: Yup.string().required().email(i18n.t('general.validation.email')).max(255)
			}),
		[]
	)
}
