import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import { styled } from '@mui/system'

import { ScreenWrapper } from '../../components/ScreenWrapper'
import { OrderDtoPickupTimeEnum } from '../../generated/api/breadlove'
import { Path } from '../../router/BaseRouter/enums'
import { orderActions, orderState } from '../../store/slices/orderSlice'
import { useSchema } from './schema'

export type Step2FormValues = InferType<ReturnType<typeof useSchema>>

const CustomRadio = styled(Radio)(() => ({
	display: 'none'
}))

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	backgroundColor: theme.palette.common.white,
	border: '1px solid #1E1E1E',
	borderRadius: '4px',
	margin: theme.spacing(0.5, 0),
	padding: theme.spacing(1.5, 3),
	width: '175px',
	textAlign: 'left',
	transition: 'background-color 0.3s, border-color 0.3s',
	'&.checked': {
		backgroundColor: '#F0E8DC'
	},
	'& .MuiTypography-root': {
		fontFamily: `"Futura Passata", "Helvetica", "Arial", sans-serif`,
		fontSize: '16px',
		fontWeight: 700,
		color: '#1E1E1E'
	}
}))

export const Step2 = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const schema = useSchema()
	const dispatch = useDispatch()
	const order = useSelector(orderState)

	const { handleSubmit, control, reset } = useForm<Step2FormValues>({
		resolver: yupResolver(schema),
		defaultValues: {
			pickupTime: undefined
		}
	})

	useEffect(() => {
		reset({
			pickupTime: order.pickupTime ? order.pickupTime : undefined
		})
	}, [])

	return (
		<ScreenWrapper
			step={2}
			routeBack={Path.STEP1}
			title={'step2.title'}
			subTitle={'step2.subTitle'}
			onSubmit={handleSubmit((data) => {
				try {
					dispatch(orderActions.setStep2(data))
					navigate(Path.STEP3)
				} catch (e) {}
			})}
		>
			<Controller
				control={control}
				name='pickupTime'
				render={({ field, fieldState }) => {
					return (
						<FormControl>
							<RadioGroup {...field}>
								{[OrderDtoPickupTimeEnum._1, OrderDtoPickupTimeEnum._2, OrderDtoPickupTimeEnum._3].map((time) => (
									<StyledFormControlLabel
										value={time}
										control={<CustomRadio />}
										label={t(`step2.form.${time}`)}
										className={field.value === time ? 'checked' : ''}
									/>
								))}
							</RadioGroup>
							{fieldState.error?.message && (
								<FormHelperText sx={{ margin: 0 }} error>
									{fieldState.error.message}
								</FormHelperText>
							)}
						</FormControl>
					)
				}}
			/>
		</ScreenWrapper>
	)
}
