import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { Footer } from '../../components/Footer'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Path } from '../../router/BaseRouter/enums'

export const Home = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	return (
		<ScreenWrapper>
			<Grid container direction='column' sx={{ height: '100vh' }}>
				<Grid item xs={6}>
					<Box display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{ height: '100%' }}>
						<Typography variant='h1'>{t('title')}</Typography>
					</Box>
				</Grid>

				<Grid item xs={2}>
					<Box display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{ height: '100%' }}>
						<Button variant='contained' size='large' sx={{ width: '176px' }} onClick={() => navigate(Path.STEP1)}>
							{t('general.button.order')}
						</Button>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{ height: '100%' }}>
						<Footer />
					</Box>
				</Grid>
			</Grid>
		</ScreenWrapper>
	)
}
