import { OrdersApi } from '../generated/api/breadlove'
import { OrderState } from '../store/slices/orderSlice'
import axios from './axios'

const ordersApi = new OrdersApi(undefined, process.env.REACT_APP_BE_URL, axios)

const PICKUP_POINT_ID = 1
const TYPE = 'b2c'
export const createOrder = (form: OrderState) => {
	form.pickupDate!.setHours(12)
	const products = form.products!.filter((value) => value.count > 0)
	return ordersApi.createOrder({
		type: TYPE,
		email: form.email,
		name: form.name!,
		open: true,
		phoneNumber: form.phoneNumber,
		pickupDate: form.pickupDate!.toISOString(),
		pickupTime: form.pickupTime,
		pickupPointId: PICKUP_POINT_ID,
		emailNotification: true,
		products: products
	})
}
