import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { OrderDtoPickupTimeEnum } from '../../generated/api/breadlove'
import { RootState } from '../index'

export type OrderState = {
	pickupDate?: Date
	pickupTime?: OrderDtoPickupTimeEnum
	phoneNumber?: string
	email?: string
	name?: string
	products?: { productId: number; count: number }[]
}

const initialState: OrderState = {}

const orderSlice = createSlice({
	name: 'order',
	initialState: initialState,
	reducers: {
		setStep1: (state, action: PayloadAction<{ pickupDate?: Date }>) => {
			state.pickupDate = action.payload.pickupDate
		},
		setStep2: (state, action: PayloadAction<{ pickupTime?: OrderDtoPickupTimeEnum }>) => {
			state.pickupTime = action.payload.pickupTime
		},
		setStep3: (state, action: PayloadAction<{ products?: { productId: number; count: number }[] }>) => {
			state.products = action.payload.products
		},
		setStep4: (state, action: PayloadAction<{ phoneNumber?: string; email?: string; name?: string }>) => {
			state.phoneNumber = action.payload.phoneNumber
			state.email = action.payload.email
			state.name = action.payload.name
		},
		reset: () => initialState
	}
})

export const orderReducer = orderSlice.reducer
export const orderActions = orderSlice.actions

const selectSlice = (state: RootState) => state.order

export const orderState = createSelector(selectSlice, (roleState) => roleState)
