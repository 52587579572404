import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { TextFieldController } from '../../components/forms/fields/TextFieldController'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Path } from '../../router/BaseRouter/enums'
import { orderActions, orderState } from '../../store/slices/orderSlice'
import { useSchema } from './schema'

export type Step4FormValues = InferType<ReturnType<typeof useSchema>>

export const Step4 = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const schema = useSchema()
	const dispatch = useDispatch()
	const order = useSelector(orderState)

	const { handleSubmit, control, reset } = useForm<Step4FormValues>({
		resolver: yupResolver(schema),
		defaultValues: {
			phoneNumber: '',
			name: '',
			email: ''
		}
	})

	useEffect(() => {
		reset({
			phoneNumber: order.phoneNumber,
			name: order.name,
			email: order.email
		})
	}, [])

	return (
		<ScreenWrapper
			step={4}
			routeBack={Path.STEP3}
			title={'step4.title'}
			subTitle={'step4.subTitle'}
			onSubmit={handleSubmit((data) => {
				try {
					dispatch(orderActions.setStep4(data))
					navigate(Path.STEP5)
				} catch (e) {}
			})}
		>
			<Box style={{ width: '100%' }}>
				<Grid container direction='column' mb={2}>
					<Grid item>
						<Typography variant='subtitle2'>{t('step4.form.name')}</Typography>
					</Grid>
					<Grid item>
						<TextFieldController name='name' control={control} />
					</Grid>
				</Grid>
				<Grid container direction='column' mb={2}>
					<Grid item>
						<Typography variant='subtitle2'>{t('step4.form.phoneNumber')}</Typography>
					</Grid>
					<Grid item>
						<TextFieldController name='phoneNumber' control={control} />
					</Grid>
				</Grid>
				<Grid container direction='column' mb={2}>
					<Grid item>
						<Typography variant='subtitle2'>{t('step4.form.email')}</Typography>
					</Grid>
					<Grid item>
						<TextFieldController name='email' control={control} />
					</Grid>
				</Grid>
			</Box>
		</ScreenWrapper>
	)
}
