import { createMigrate, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { migrations } from './migrations'
import { orderReducer } from './slices/orderSlice'
import { userOptionsReducer } from './slices/userOptionsSlice'

const persistConfig = {
	key: 'root',
	version: 0,
	migrate: createMigrate(migrations),
	storage,
	whitelist: ['userOptions', 'order']
}

export const persistedReducer = persistReducer(
	persistConfig,
	combineReducers({
		order: orderReducer,
		userOptions: userOptionsReducer
	})
)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		})
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
