import React, { FC, FormEventHandler, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CheckIcon from '@mui/icons-material/Check'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Button, Grid, IconButton, LinearProgress, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import { Path } from '../../router/BaseRouter/enums'

type Props = {
	title?: string
	subTitle?: string
	buttonTitle?: string
	buttonDisabled?: boolean
	routeBack?: Path
	step?: number
	children: ReactNode
	onSubmit?: FormEventHandler
}

const STEPS = 6
export const ScreenWrapper: FC<Props> = ({
	title,
	subTitle,
	buttonTitle,
	buttonDisabled,
	step,
	routeBack,
	children,
	onSubmit
}) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	return (
		<Box sx={{ backgroundColor: step ? '#FFFFFF' : '#FAF8F5', height: '100vh' }}>
			{step && (
				<LinearProgress
					variant='determinate'
					value={Math.ceil(step * (100 / STEPS))}
					sx={{
						'& .MuiLinearProgress-bar': {
							backgroundColor: '#DF8DB1'
						},
						backgroundColor: '#FAEEF6'
					}}
				/>
			)}
			<Container sx={{ minWidth: '100%' }}>
				{(routeBack || step) && (
					<Grid container mt={2}>
						<Grid item xs={2}>
							{routeBack && (
								<IconButton
									aria-label='back'
									title={t('back')}
									onClick={() => navigate(routeBack)}
									sx={{
										color: '#1E1E1E'
									}}
								>
									<ChevronLeftIcon sx={{ width: '43px', height: '43px' }} />
								</IconButton>
							)}
						</Grid>
						<Grid item xs={8}>
							{step && (
								<Box
									display='flex'
									justifyContent='center'
									alignItems='center'
									textAlign='center'
									style={{ height: '100%' }}
								>
									<Typography variant='body1'>
										{step} / {STEPS}
									</Typography>
								</Box>
							)}
						</Grid>
						<Grid item xs={2}></Grid>
					</Grid>
				)}
				{title && (
					<Box display='flex' justifyContent='flex-start' alignItems='center' mt={2}>
						<Box>
							<Typography variant='h2'>{t(title)}</Typography>
						</Box>
					</Box>
				)}
				{subTitle && (
					<Box display='flex' justifyContent='flex-start' alignItems='center' mt={2} mb={3}>
						<Box>
							<Typography variant='body1'>{t(subTitle)}</Typography>
						</Box>
					</Box>
				)}
				<form onSubmit={onSubmit}>
					<Box display='flex' justifyContent='flex-start' alignItems='center'>
						{children}
					</Box>
					{onSubmit && (
						<Box display='flex' justifyContent='flex-start' alignItems='center' mt={2} pb={2}>
							<Button
								type='submit'
								variant='contained'
								size='large'
								endIcon={!buttonTitle ? <CheckIcon /> : undefined}
								sx={buttonTitle ? { width: '176px' } : undefined}
								disabled={buttonDisabled}
							>
								{buttonTitle ? t(buttonTitle) : t('general.button.ok')}
							</Button>
						</Box>
					)}
				</form>
			</Container>
		</Box>
	)
}
