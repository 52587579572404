import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHelperText, Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { NumInputController } from '../../components/forms/fields/NumInputController'
import { toast } from '../../components/LsToast'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { TableSkeleton } from '../../components/TableSkeleton'
import { ProductDto } from '../../generated/api/breadlove'
import { Path } from '../../router/BaseRouter/enums'
import { getProducts } from '../../service/products.service'
import { orderActions, orderState } from '../../store/slices/orderSlice'
import { useSchema } from './schema'

export type Step3FormValues = InferType<ReturnType<typeof useSchema>>
export const Step3 = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const schema = useSchema()
	const dispatch = useDispatch()
	const order = useSelector(orderState)
	const [productsLoading, setProductsLoading] = useState<boolean>(true)
	const [products, setProducts] = useState<ProductDto[]>([])
	const {
		handleSubmit,
		control,
		reset,
		getValues,
		setValue,
		formState: { errors }
	} = useForm<Step3FormValues>({
		resolver: yupResolver(schema),
		defaultValues: {
			products: []
		}
	})

	useEffect(() => {
		const productsForm = products.map((value) => ({ productId: value.id, count: 0 }))
		if (order.products && order.products?.length > 0 && productsForm && productsForm.length > 0) {
			order.products.forEach((order) => {
				productsForm.find((value) => {
					return value.productId === order.productId
				})!.count = order.count
			})
		}
		productsForm.sort((a, b) => a.productId - b.productId)

		reset({
			products: productsForm ? productsForm : []
		})
	}, [products])

	useEffect(() => {
		void loadProducts()
	}, [])

	const loadProducts = async () => {
		setProductsLoading(true)
		try {
			const res = await getProducts(new Date())
			setProducts(res.data)
		} catch {
			toast.error(t('general.notification.unexpectedError'))
		} finally {
			setProductsLoading(false)
		}
	}

	return (
		<ScreenWrapper
			step={3}
			routeBack={Path.STEP2}
			title={'step3.title'}
			subTitle={'step3.subTitle'}
			onSubmit={handleSubmit((data) => {
				try {
					dispatch(orderActions.setStep3(data))
					navigate(Path.STEP4)
				} catch (e) {}
			})}
		>
			<Grid container direction='column'>
				<Grid item>
					{!productsLoading && (
						<Grid container direction='column'>
							{products
								.sort((a, b) => a.id - b.id)
								.map((product, index) => (
									<Grid
										key={product.id}
										item
										mb={2}
										display='flex'
										justifyContent='space-between'
										alignItems='center'
										sx={{ border: '1px solid #1E1E1E', borderRadius: '4px', padding: (theme) => theme.spacing(3, 2) }}
									>
										<Box>
											<Typography variant='h4'>{product.name}</Typography>
											<Box>
												<Typography variant='h5' component='span'>
													{product.price}
												</Typography>
												<Typography
													variant='caption'
													component='span'
													sx={{
														marginLeft: '2px',
														marginRight: (theme) => theme.spacing(1),
														alignSelf: 'flex-start',
														position: 'relative',
														top: '-3px'
													}}
												>
													00
												</Typography>
												<Typography variant='h5' component='span'>
													Kč
												</Typography>
											</Box>
										</Box>
										<Box>
											<NumInputController
												getValue={getValues}
												setValue={setValue}
												name={`products.${index}.count`}
												control={control}
												max={product.limit}
											/>
										</Box>
									</Grid>
								))}
						</Grid>
					)}
					{productsLoading && <TableSkeleton spacing={3} height={88} size={10} />}
				</Grid>
				{errors?.products?.root && (
					<Grid item>
						<FormHelperText sx={{ margin: 0 }} error>
							{errors.products.root.message}
						</FormHelperText>
					</Grid>
				)}
			</Grid>
		</ScreenWrapper>
	)
}
