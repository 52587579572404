import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Box from '@mui/material/Box'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { useTheme } from '@mui/material/styles'

import { Layout } from '../../components/Layout'
import { ErrorBoundary } from '../ErrorBoundary'

export const App: React.FC = () => {
	const theme = useTheme()
	return (
		<Box
			sx={{
				backgroundColor: theme.palette.background.default,
				height: '100%',
				width: '100%',
				color: theme.palette.primary.main
			}}
		>
			<StyledEngineProvider injectFirst>
				<BrowserRouter>
					<ErrorBoundary>
						<Layout />
					</ErrorBoundary>
				</BrowserRouter>
			</StyledEngineProvider>
		</Box>
	)
}
