import React from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import InputAdornment from '@mui/material/InputAdornment'
import { TextFieldVariants } from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'

type Props<Values extends FieldValues> = {
	name: FieldPath<Values>
	control: Control<Values>
	variant?: TextFieldVariants
	disabled?: boolean
}

const isWeekend = (date: Date) => {
	return date.getDay() === 0 || date.getDay() === 1 || date.getDay() === 6
}

export const DatePickerController = <Values extends FieldValues>({
	name,
	control,
	variant = 'filled',
	disabled
}: Props<Values>) => {
	return (
		<Controller
			name={name}
			control={control}
			disabled={disabled}
			render={({ field: { onChange, value, ref }, fieldState }) => (
				<DatePicker
					disablePast
					format='EEEE - dd.MM.yyyy'
					shouldDisableDate={isWeekend}
					value={value}
					onChange={onChange}
					timezone='UTC'
					slotProps={{
						textField: {
							InputProps: {
								endAdornment: (
									<InputAdornment position='end'>
										<CalendarMonthIcon />
									</InputAdornment>
								)
							},
							inputRef: ref,
							size: 'medium',
							variant: variant,
							fullWidth: true,
							disabled: disabled,
							sx: {
								'& .MuiInputBase-input': {
									padding: '17px 16px',
									textTransform: 'capitalize'
								}
							},
							error: !!fieldState.error?.message,
							helperText: fieldState.error?.message
						}
					}}
					sx={{ width: '100%' }}
				/>
			)}
		/>
	)
}
